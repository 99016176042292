<template>
	<div class="card">
		<div class="grid formgrid">
			<div class="col-12 xl:col-12">
				<h5>{{$route.name}}</h5>
			</div>
			<div class="col-12">
				<DataTable :value="products" class="datatable-gridlines datatable-striped datatable-sm datatable-responsive datatable-customers"
					dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<div>
								<InputSwitch v-model="$store.state.opc"  style="margin-top: 0" class="mr-2"/>
								<InputText id="fecha"  v-model="$store.state.ini" type="date" v-if="!$store.state.opc" class="mr-2"/>
								<InputText id="fecha"  v-model="$store.state.fin" type="date" v-if="!$store.state.opc" class="mr-2"/>
								<Dropdown id="state" v-model="$store.state.year" :options="$store.state.years" optionLabel="nombre" class="mr-2" placeholder="Selecciona..." @change="$store.state.mes=nul" v-if="$store.state.opc"></Dropdown>
								<Dropdown id="state" v-model="$store.state.mes" :options="$store.state.meses" optionLabel="nombre" class="mr-2" placeholder="Selecciona..." v-if="$store.state.opc"></Dropdown>
								<Dropdown v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Selecciona..." class="mr-2"></Dropdown>
								<Button icon="pi pi-search" class="p-button-rounded p-button-info mr-2" @click="Buscar"/>
							</div>
							Total: {{products.length}}
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
							<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="Nuevo" v-if="insert"/>
						</div>
					</template>
					<Column field="control" header="Nº Control" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.control}}
						</template>
					</Column>
					<Column field="fechad" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.fechad}}
						</template>
					</Column>
					<Column field="proveedor" header="Especialista" :sortable="true" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.especialista}}
						</template>
					</Column>
					<Column field="rif" header="Rif">
						<template #body="slotProps">
							{{slotProps.data.rif}}
						</template>
					</Column>
					<Column header="Iva">
						<template #body="slotProps">
                            ${{Moneda(slotProps.data.monto*slotProps.data.iva)}}
						</template>
					</Column>
					<Column header="Monto">
						<template #body="slotProps">
							${{Moneda(slotProps.data.monto)}}
						</template>
					</Column>
					<Column header="Monto Bs">
						<template #body="slotProps">
							Bs. {{Moneda((-1)*slotProps.data.recibidobs)}}
						</template>
					</Column>
					<Column header="Estatus">
						<template #body="slotProps">
						<div v-if="slotProps.data.activo">
							<strong v-if="slotProps.data.pagado" style="color:green">
								Pagado
							</strong>
							<strong v-if="!slotProps.data.pagado && slotProps.data.recibido>0" style="color:orange">
								Pago Parcial<br>
							</strong>  
							<strong v-if="!slotProps.data.pagado && slotProps.data.recibido==0" style="color:red">
								Pendiente
							</strong>  
						</div>
						<div v-else>
							<strong style="color:grey">
								Anulado
							</strong> 
						</div>
						</template>
					</Column>				
                    <Column header="Acciones" headerStyle="width: 200px">
						<template #body="slotProps">
                            <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary mr-2" @click="VerPDF(slotProps.data)" v-tooltip.bottom="'PDF'" v-if="edit && slotProps.data.activo"/>
							<Button icon="pi pi-play" class="p-button-rounded p-button-secondary mr-1" @click="Procesar(slotProps.data)" v-tooltip.bottom="'Procesar'" v-if="edit && slotProps.data.activo && !slotProps.data.pagado"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-1" @click="Confirmar(slotProps.data)" v-tooltip.bottom="'Eliminar'" v-if="del && slotProps.data.activo && !slotProps.data.pagado"/>
							<Button icon="pi pi-envelope" class="p-button-rounded p-button-success mr-2" @click="Enviar(slotProps.data)" v-tooltip.bottom="'Enviar'" v-if="edit && slotProps.data.pagado"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Editar ID'+product.id" :modal="true" class="p-fluid" :maximizable="true">
			<div class="fluid">       
				<div class="grid fluid mt-3">
					<div class="col-6">
						<label for="name">Especialista</label>
						<Dropdown v-model="product.proveedor" :options="this.proveedores" optionLabel="nombre" placeholder="Selecciona..." @change="Seleccion"></Dropdown>
						<small class="p-invalid" v-if="!product.proveedor">Es requirido</small>
					</div>
					<div class="col-3">
						<label for="name">Rif</label>
						<InputText id="name" v-model="product.proveedor.rif" disabled v-if="product.proveedor"/>
					</div>
					<div class="col-3">
						<label for="name">Fecha</label>
						<InputText v-model="product.fecha" type="date" required="true"/>
                        <small class="p-invalid" v-if="!product.fecha">Es requerido.</small>
					</div>
					<div class="col-2">
						<h6 style="margin-top: 0">
							Factura: 
							<strong v-if="product.factura">SI</strong>
							<strong v-else>NO</strong>
						</h6>
						<InputSwitch v-model="product.factura" /> 	
					</div>
					<div class="col-2">
						<label for="price">IVA</label>
						<InputNumber v-model="product.iva" suffix="%" :disabled="product.pagado"/>
					</div>
					<div class="col-8">
						<label for="name">Observación</label>
						<Textarea v-model="product.observacion" rows="2" cols="20"/>
                        <small class="p-invalid" v-if="!product.observacion">Es requerido.</small>
					</div>
					<div class="col-3">
						<label for="name">Desde</label>
						<InputText v-model="ini" type="date" @change="Filtrar"/>
					</div>
					<div class="col-3">
						<label for="name">Hasta</label>
						<InputText v-model="fin" type="date" @change="Filtrar"/>
					</div>
					<div class="col-12" v-if="product.proveedor">
						<DataTable :value="resultado" responsiveLayout="scroll" dataKey="id"
							v-model:selection="selectedCustomers" :selectAll="selectAll" 
							@select-all-change="onSelectAllChange" @row-select="onRowSelect" 
							@row-unselect="onRowUnselect">
							<Column header="N°" headerStyle="width: 3rem">
								<template #body="slotProps">
									{{ (slotProps.index + 1) }}
								</template>
							</Column>
							<Column field="fechad" header="Fecha"></Column>
							<Column field="nombre" header="Paciente">
								<template #body="slotProps">
									{{(slotProps.data.nombre)}}  {{(slotProps.data.apellido)}}
								</template>
							</Column>
							<Column field="producto" header="Descripción">
								<template #body="slotProps">
									{{(slotProps.data.especialidad)}} - {{(slotProps.data.producto)}}
								</template>
							</Column>
							<Column field="espec" header="Monto" headerStyle="width: 100px">
								<template #body="slotProps">
									{{(slotProps.data.abrev+slotProps.data.espec)}} ({{(slotProps.data.abrev+slotProps.data.monto)}})
								</template>
							</Column>
							<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
						</DataTable>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="grid fluid mt-3">
				<div class="col-6">
					<h5>
						<strong>
							Citas: {{selectedCustomers.length}}
						</strong>
					</h5>
				</div>
				<div class="col-6">
					<h3>
						<strong>
							Monto a Pagar: ${{Moneda(product.monto*(1+product.iva/100))}}
						</strong>
					</h3>
				</div>
				</div>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="deleteDialog"  style="width: 30vw" :header="'Anular ID'+product.id" :modal="true">
            <div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres anular?</span>
			</div>
			<template #footer>
				<Button label="Si" icon="pi pi-check" class="p-button-secondary" @click="Eliminar" :loading="$store.state.loading" :disabled="$store.state.loading==true"/>
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="DialogProcesar" style="width: 60vw" header="Procesar Pago" :modal="true" class="p-fluid" :maximizable="true">
			<Toast/>
			<div class="field">
				<div class="grid">
					<div class="col-12">
						<Card>
							<template #content>
								<div class="fluid formgrid grid">
									<div class="col-6">
										<h5>Control Nº  {{product.control}}</h5>
									</div>
                                    <div class="col-6">
										<h5>Fecha: {{product.fechad}}</h5>
									</div>
									<div class="col-12">
										<h5>{{product.especialista}} - {{product.rif}}</h5>
									</div>
									<div class="col-6">
										<h5><strong>Monto: $ {{Moneda(product.monto)}}</strong></h5>
									</div>
								</div>
							</template>
						</Card>	
					</div>
					<div class="col-12">
						<ReportarPago :tipopago="tipopago" :deuda="totales" :moneda="product.moneda"/>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Procesar" icon="pi pi-check" class="p-button-secondary"  @click="Ejecutar()" :loading="$store.state.loading" :disabled="(parseFloat($store.state.pago.deuda).toFixed(2) > parseFloat($store.state.pago.monto).toFixed(2))"/>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="DialogProcesar = false"/>
			</template>
		</Dialog>


	</div>

</template>

<script>
	import API from "../service/API";
	import ReportarPago from '@/components/ReportarPago.vue';
	const Consulta = new API('Administracion','Honorarios');
    import Funciones from "../service/Class";
    const Aplic = new Funciones();
	import PDF from "../service/PDF";

	export default {
		components: {
			ReportarPago,
			//DetallePago,
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				product: {},
				products: [],
                DialogProcesar: false,
				productDialog: false,
				deleteDialog: false,
				selectedCustomers: [],
				filters: {},
				size: '60vw',
                dataprincipal: [],
				opcion: {nombre: 'Todas', code: 0},
				opciones: [
					{nombre: 'Todas', code: 0},
					{nombre: 'Pagadas', code: 1},
					{nombre: 'Pendientes', code: 2},
					{nombre: 'Anuladas', code: 3}
				],
                proveedores: [],
                tipopago: [],
                caja: 0,
                banco: 0,
                cajaf: 0,
                bancof: 0,
                formapagos: [],
                totales: {
                    subtotal: 0,
                    descuento: 0,
                    iva: 0,
					final: 0,
					finalbs: 0,
                },
				ini: null,
				fin: null,
				resultado: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		watch: {
			selectedCustomers() {
				this.Calcular();
			}
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.selectedCustomers = [];
				Consulta.Ini().then(response => {
					//this.$store.state.error = response.result;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
                    this.tipopago = response.result.tipopago;
					this.proveedores = response.result.proveedores;
					this.$store.commit('Loading');
				}); 
			},
			Seleccion() {
				this.resultado = [];
				this.selectedCustomers = [];
				if(this.product.proveedor){
					this.resultado = this.product.proveedor.detalle;
				}
				this.Filtrar();
			},
			Enviar(data){
				Consulta.Procesar('Enviar',{
						product: data,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
					});
			},
			Filtrar() {
				if(this.ini && this.fin && this.ini<=this.fin){
					this.selectedCustomers = [];
					this.resultado = this.product.proveedor.detalle.filter(val => 
						val.fecha <= this.fin && val.fecha >= this.ini
					);	
				} 
			},
            Buscar(){
				this.selectedCustomers = [];
				let opciones = {
					filt: this.opcion.code,
					opc: this.$store.state.opc,
					ini: this.$store.state.ini,
					fin: this.$store.state.fin,
					mes: this.$store.state.mes.id,
					year: this.$store.state.year.id,
				};
				Consulta.Procesar('Buscar',{
                    product: opciones,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					if (response.result) {
						this.products = response.result;
					}
				});
            },
			VerPDF(data){
				Consulta.Procesar('VER',{
						product: data,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						if (response.result) {
							data.detalle = response.result;
							const doc = new PDF();
							doc.Honorarios(data);
						}
					});
			},
			Calcular(){
				let total = 0;
				for(let sale of this.selectedCustomers) {
					total += parseFloat(sale.espec);
				}
				this.product.monto = total;
			},
			Nuevo() {
				this.Mostrar();
				this.nuevo = true;
				this.product = {};
                this.product.id = '';
                this.product.moneda =  2;
                this.product.monto = 0;
                this.product.iva = 0;
                this.product.observacion = 'Ninguna';
                this.product.factura = false;
                this.product.retencion = false;
                this.product.vence = false;
                this.product.tasa = this.$store.state.tasa;
                this.product.validopor = 1;
				this.productDialog = true;
			},
			Editar(product) {
				this.nuevo = false;
				this.product = {...product};
                this.product.proveedor =  this.proveedores.filter(val => val.id == this.product.proveedor_id)[0];
                this.product.moneda =  this.$store.state.monedas.filter(val => val.id == this.product.moneda)[0];
                this.productDialog = true;
			},
			Guardar() {
				if (this.product.proveedor == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Selecciones el Especialista', life: 5000});
				} else if (this.product.fecha == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Fecha', life: 5000});
				} else if (this.product.monto <= 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Monto', life: 5000});
				} else if (!(this.product.observacion)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Observación', life: 5000});
				} else {
					this.product.selectedCustomers = this.selectedCustomers;
					Consulta.Procesar('INS',{
						product: this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.Buscar();
							this.productDialog = false;
							this.product = {};
						}
					});
				}
			},
			Confirmar(product) {
				this.product = product;
				this.deleteDialog = true;
			},
			Eliminar() {
				Consulta.Procesar('DEL',{
					product: 	this.product
				}).then(response => {
					this.$store.commit('Loading');
					this.$toast.add(Consulta.Mens(response.result));
					if (response.result) {this.Buscar();}
				});
				this.deleteDialog = false;
				this.product = {};
			},
			Procesar(product) {
				this.product = {...product};
				this.totales.final = parseFloat(product.monto);
				this.DialogProcesar = true;
			},
			Ejecutar() {
				if (!this.product == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione', life: 5000});
				} else if (this.$store.state.pago.monto==0 && this.$store.state.pago.montobs==0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la forma de pago', life: 10000});
				} else {
					this.product.pagos = this.$store.state.pago;
					this.product.tasa = this.$store.state.tasa;
					Consulta.Procesar('Procesar',{
						product: 	this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consulta.Mens(response.result));
						if (response.result) {
							this.$store.commit('LimpiarPagos');
							this.DialogProcesar = false;
							this.Buscar();
						} 
					});
				}
            },

            Moneda(value) {
                return Aplic.Moneda(value);
             },



		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
